import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const CulinaryArticleCardFragment = gql`
  fragment CulinaryArticleCardFragment on ArticleUnion {
    __typename

    ... on CulinaryArticle {
      id
      slug
      title
      title_homepage
      mainImage {
        ...WediaImageFragment
      }
      thumbnail01 {
        ...WediaImageFragment
      }
      positiveFood
      brand {
        id
        pictureLogo {
          ...WediaImageFragment
        }
      }
      tagsConsumptionTime {
        name
      }
      tagsTheme {
        name
      }
    }

    ... on Article {
      id
      slug
      title
      type
      mainImage {
        ...WediaImageFragment
      }
      thumbnail01 {
        ...WediaImageFragment
      }
      brand {
        id
        pictureLogo {
          ...WediaImageFragment
        }
      }
      tagTheme {
        name
      }
    }
  }

  ${WediaImageFragment}
`
